import React from 'react';
// Structure imports
import Layout from '@components/structure/Layout.jsx';
import Seo from '@components/utility/SEO';
import { KfButton,KfAnchorLink } from '@klickinc/kf-react-components';
import Hero from '@components/content/hero/Hero';
import Image from '@components/utility/Image';
import './styles.scss';

const ServerError = () => {
	return (
		<Layout>
			<Seo title="500 Internal Server Error | EMFLAZA® (deflazacort)"/>
			<div className='relative min-h-screen md:min-h-[50vh] xl:min-h-screen page--500'>
				<Hero
					heroImage='server-error-rectangle.png'
					heroImageMobile='server-error-rectangle-mobile.png'
					title={
						<span>
							<span className='text-6xl md:text-8xl'> 500</span>
							<br />
							<span className='text-4xl md:text-7xl'>Internal Server Error</span>
						</span>
					}
					addedClass='blue-bg '
					useBreadcrumbs={false}
				/>
				<div className='pt-12 mb-56 md:mb-16 xl:mb-56 lg:pt-0'>
					<div className='px-8 text-left text-emflaza-blue-600 lg:px-20 sm:max-w-2xl'>
						<h2 className='text-4xl font-semibold font-gotham'>SORRY!</h2>
						<p className='pt-2 pb-12 text-sm'>
							It appears something has gone wrong on our end. Please try
							again later. We apologize for the inconvenience.
						</p>
						<div className='text-center sm:text-left'>
							<KfButton addedClass='bg-emflaza-orange-300 h-11 w-60 text-center text-base text-emflaza-white-100'>
								<KfAnchorLink url='/getting-started/prescription-start-form' >Learn More About Prescribing</KfAnchorLink>
							</KfButton>
						</div>
					</div>
					<div className='hidden xl:block right-0 -bottom-0 absolute'>
						<Image imageName='404-500-anchored-deco.png'></Image>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default ServerError;
